import { navigate } from "gatsby"
import { Formik, Form } from "formik"
import React, { useContext, useEffect } from "react"

import Layout from "../Layout"
import Container from "../Layout/Container"
import Section from "../Elements/Section"
import Message from "../Elements/Message"
import PaymentMethod from "./PaymentMethod"
import ActionButtons from "../Elements/ActionButtons"

import { EpharmacyContext } from "./EpharmacyContext/EpharmacyContext"
import {
  accumulatePrice,
  formatPrice,
} from "../Epharmacy/services/computations"
import { paymentDetailsValidationSchema } from "./utils/paymentDetailsValidationSchema"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"

const PaymentDetails = ({ pageContext }) => {
  const { module } = pageContext
  const { epharmacyState, epharmacyDispatch } = useContext(EpharmacyContext)
  const cartTotal = formatPrice(
    (accumulatePrice(epharmacyState?.medicines) + 100).toFixed(2)
  )

  useEffect(() => {
    epharmacyDispatch({ type: "GET_CONTEXT_FROM_SESSION" })
  }, [epharmacyDispatch])

  return (
    <Layout
      {...module}
      display={{
        footer: false,
        helpCenterBanner: false,
        bottomMargin: true,
        progressBar: true,
      }}
    >
      <Container isCentered>
        <Formik
          initialValues={{
            paymentOption: "",
            ...epharmacyState,
            paymentOption:
              epharmacyState?.paymentOption?.toLowerCase() !==
                "cash on delivery" && epharmacyState?.preferredBank,
          }}
          enableReinitialize={true}
          onSubmit={async (values, { setErrors, setFieldValue }) => {
            if (values?.paymentOption.trim() === "")
              return setErrors(
                "paymentOption",
                "Please select a payment option"
              )

            if (
              values?.paymentOption === "Cash on Delivery" &&
              parseFloat(cartTotal.replace(",", "")) > values?.changeFor
            )
              return setErrors({
                changeFor:
                  "Please input an amount sufficient to cover your order total.",
              })

            if (values?.paymentOption !== "Cash on Delivery") {
              values["preferredBank"] = values["paymentOption"]
              values["paymentOption"] = "Bank Deposit"
            }

            epharmacyDispatch({
              type: "SAVE_EPHARMACY",
              payload: values,
            })

            epharmacyDispatch({ type: "SAVE_CONTEXT_TO_SESSION" })
            navigate(pageContext?.nextPath)
          }}
          validationSchema={paymentDetailsValidationSchema}
        >
          {({ values, setFieldValue, isValid, submitCount }) => (
            <Form>
              <p>
                You may choose cash-on-delivery (COD) for faster processing. If
                you select COD, please prepare your payment in advance. Your
                order will be delivered in 1-2 working days.
              </p>
              <Section className="mt-3 mb-3">
                <PaymentMethod
                  values={values}
                  setFieldValue={setFieldValue}
                  cartTotal={cartTotal}
                />
                {!isValid && submitCount > 0 && (
                  <p className="has-text-danger mx-1">
                    {values?.paymentOption === ""
                      ? "Please select a payment option"
                      : "Please specify the details on the payment option you have chosen"}
                  </p>
                )}
              </Section>

              <ActionButtons
                submit={{
                  label: "Next: Summary",
                  disabled:
                    !values.paymentOption ||
                    (values?.paymentOption === "Cash on Delivery" &&
                      !values?.changeFor),
                }}
                back={{
                  label: <FontAwesomeIcon icon={faChevronLeft} />,
                  link: pageContext.backPath,
                }}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default PaymentDetails
