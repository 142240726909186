export const formatPrice = (config) => {
  let priceFloat = parseFloat(config)

  return priceFloat.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

export const accumulateQuantity = (medicines) => {
  let total = 0
  let purchaseQty = 0
  let assistanceQty = 0
  medicines.forEach((medicine) => {
    purchaseQty += medicine.qty
    assistanceQty += medicine.assistanceQuantity
    total += medicine.qty
    total += medicine.assistanceQuantity
  })

  return {
    totalQuantity: total,
    purchaseQuantity: purchaseQty,
    assistanceQuantity: assistanceQty,
  }
}

export const generatePrice = (medicine) => {
  if (!medicine.price_list) return (0).toFixed(2)
  let vatex = medicine.price_list.vatex_unit_price || 0
  let vat = medicine.price_list.vat || 0
  return parseFloat(vatex + vat).toFixed(2)
}

export const accumulatePrice = (medicines) => {
  let total = 0
  medicines.forEach((medicine) => {
    total += medicine.qty * parseFloat(generatePrice(medicine))
  })
  return total
}

export const accumulateSubtotal = (medicines) => {
  let total = 0
  medicines.forEach((medicine) => {
    total += medicine.qty * parseFloat(generateSubtotal(medicine))
  })
  return total
}

export const generateSubtotal = (medicine) => {
  if (!medicine.price_list) return (0).toFixed(2)
  let vatex = medicine.price_list.vatex_unit_price || 0
  return parseFloat(vatex).toFixed(2)
}

export const generateDeliveryFee = (province) => {
  const MM_DELIVERY_FEE = 100.0
  const PROVINCIAL_DELIVERY_FEE = 300.0

  if (province === "Metro Manila") return MM_DELIVERY_FEE
  return PROVINCIAL_DELIVERY_FEE
}

export const checkIfHasRxRequired = (medicines) => {
  if (medicines.length === 0) return true
  return medicines.some((medicine) => medicine.rxRequired === true)
}
