import React, { Fragment, useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames"

import styles from "./utils/epharmacy.module.scss"
import paymentOptions from "./utils/paymentMethods.json"
import { generateFollowUp } from "./services/paymentOptions"
import { EpharmacyContext } from "./EpharmacyContext/EpharmacyContext"

const PaymentMethod = ({ values, setFieldValue, isNationwide, cartTotal }) => {
  const { epharmacyState, epharmacyDispatch } = useContext(EpharmacyContext)
  const data = useStaticQuery(graphql`
    {
      paymentCod: file(relativePath: { eq: "icons/payment-cod.png" }) {
        childImageSharp {
          fixed(width: 46) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      paymentBDO: file(relativePath: { eq: "icons/payment-bdo.png" }) {
        childImageSharp {
          fixed(width: 52) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      paymentBPI: file(relativePath: { eq: "icons/payment-bpi.png" }) {
        childImageSharp {
          fixed(width: 51) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      paymentMetrobank: file(
        relativePath: { eq: "icons/payment-metrobank.png" }
      ) {
        childImageSharp {
          fixed(width: 51) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      paymentUnionBank: file(
        relativePath: { eq: "icons/payment-unionbank.png" }
      ) {
        childImageSharp {
          fixed(width: 76) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      paymentBank: file(
        relativePath: { eq: "icons/payment-bank-transfer.png" }
      ) {
        childImageSharp {
          fixed(
            duotone: { highlight: "#324b5c", shadow: "#324b5c" }
            width: 80
          ) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const handlePaymentValidation = (paymentOption) => {
    switch (paymentOption) {
      case "Cash on Delivery":
        return (
          parseFloat(values.changeFor) < parseFloat(cartTotal.replace(",", ""))
        )
      default:
        return
    }
  }

  return (
    <div
      className={classNames(
        "columns is-centered",
        styles["paymentDetails__paymentOptions"]
      )}
    >
      {paymentOptions.map((paymentOption) => {
        if (
          paymentOption.label?.toLowerCase() === "cash on delivery" &&
          epharmacyState?.address?.province?.value.toLowerCase() !==
            "metro manila"
        ) {
          return null
        }

        return (
          <Fragment>
            <div
              className={classNames(
                "",
                styles[
                  `paymentDetails__paymentOption${
                    values.paymentOption === paymentOption.label ? "Active" : ""
                  }`
                ]
              )}
              onClick={() => {
                setFieldValue("paymentOption", paymentOption.label)
                setFieldValue("changeFor", "")
              }}
            >
              <div
                className={classNames(styles["paymentDetails__radioButton"])}
              >
                {values.paymentOption === paymentOption.label && (
                  <div
                    className={classNames(
                      styles["paymentDetails__radioButtonChecked"]
                    )}
                  ></div>
                )}
              </div>
              <Img
                className="ml-1"
                fixed={data[paymentOption.image]?.childImageSharp?.fixed}
              />
              <p className=" ml-1">{paymentOption.label}</p>
            </div>

            {values.paymentOption === paymentOption.label &&
              paymentOption?.followUps?.map((followUp) => (
                <div
                  className={classNames(
                    "notification is-light ml-1",
                    styles["paymentDetails__form"]
                  )}
                >
                  {paymentOption?.message && (
                    <div className="has-background-white  p-1 m-1 mb-1">
                      {paymentOption?.message}
                    </div>
                  )}

                  <div className="has-background-white  p-1 m-1">
                    {generateFollowUp({
                      values,
                      followUp,
                      cartTotal,
                      setFieldValue,
                      selectedPaymentOption: values.paymentOption,
                    })}
                  </div>
                </div>
              ))}
          </Fragment>
        )
      })}
    </div>
  )
}

export default PaymentMethod
