import React from "react"
import classNames from "classnames"
import FormInput from "../../Elements/Form/FormInput"
import FormRadio from "../../Elements/Form/FormRadio"

export const generateFollowUp = ({
  values,
  followUp,
  cartTotal,
  setFieldValue,
  selectedPaymentOption,
  t,
}) => {
  let isCOD = selectedPaymentOption === "Cash on Delivery"
  let inputHelperText = isCOD ? `${"Order Total"}: P${cartTotal}` : ""

  if (t) {
    followUp.label = t(followUp.label)
    followUp.helper = t(followUp.helper)
  }

  switch (followUp.followUpType) {
    case "input":
      return (
        <FormInput
          {...followUp}
          helper={inputHelperText}
          addonLeft={
            followUp?.hasAddons && (
              <button class="button is-static">{followUp?.addonLeft}</button>
            )
          }
        />
      )
    case "radio":
      return (
        <FormRadio
          {...followUp}
          value={values[followUp?.name]}
          title={followUp?.label}
          formFields={followUp}
          formValues={values}
          setFieldValue={setFieldValue}
          isRequired={!!followUp?.required}
        />
      )
    case "message":
      return (
        <p className={classNames("", followUp.className || "")}>
          {followUp?.message}
        </p>
      )
    default:
      return null
  }
}
